@import "../../assets/scss/variables";

.mainMenu {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: outside none none;
    li {
        margin: 0 22px;
        a {
            font-size: 18px;
            font-weight: 500;
            line-height: 59px;

            display: block;

            transition: all 0.3s ease 0s;

            color: #FEFEFE;
            &:hover {
                color: $hover-color;
            }
        }
    }
}

// Dropdown Menu
@media only screen and (min-width: 992px) {
    .mainMenu {
        > li {
            &.drop {
                position: relative;

                & > a {
                    position: relative;

                    padding-right: 20px;
                    svg {
                        font-size: 15px;

                        position: absolute;
                        top: 50%;
                        right: 0;

                        transform: translateY(-50%);

                        color: #FFF;
                    }
                }
                > ul {
                    &.dropdownMenu {
                        position: absolute;
                        z-index: 99;
                        top: 120%;
                        left: 0;

                        visibility: hidden;

                        width: 260px;
                        margin: 0;
                        padding: 20px 30px;

                        transition: all 0.5s ease 0s;

                        opacity: 0;
                        border-top: 3px solid $theme-color;
                        background: #FFF none repeat scroll 0 0;
                        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
                        li {
                            margin: 0;
                            a {
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 21px;

                                display: block;

                                padding: 4px 0;

                                transition: all 0.5s ease 0s;
                                text-transform: capitalize;

                                color: #444;
                                &:hover {
                                    padding-left: 5px;

                                    color: $hover-color;
                                }
                            }
                        }
                    }
                }

                &:hover {
                    & > a {
                        color: $theme-color;
                        svg {
                            color: $theme-color;
                        }
                    }
                }
            }
        }
    }
    .mainMenu li.drop:hover ul.dropdownMenu {
        top: 100%;

        visibility: visible;

        opacity: 1;
    }
}
