.pricing {
    margin-top: 40px;
    padding: 58px 0 35px;

    text-align: center;

    background: #FFF none repeat scroll 0 0;
    box-shadow: 0 0 10px rgba(1, 15, 10, 0.1);
    &.active {
        .content {
            span {
                &::before {
                    background: linear-gradient(-90deg, #FEFFFF, #E8F6FF);
                }
            }
            ul {
                li {
                    &::before {
                        background-image: linear-gradient(
                        to right,
                        rgba(248, 217, 214, 0),
                        rgba(248, 217, 214, 0.8) 40%,
                        rgba(248, 217, 214, 0.8) 60%,
                        rgba(248, 217, 214, 0)
                        );
                    }
                }
            }
        }
    }
    .content {
        ul {
            margin: 0 0 17px;
            padding: 0;

            list-style: outside none none;
            li {
                font-size: 16px;
                font-weight: 500;

                position: relative;

                padding: 13px 0;

                color: #010F54;
                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;

                    width: 100%;
                    height: 0.125rem;

                    content: "";

                    background-image: linear-gradient(
                    to right,
                    rgba(233, 246, 255, 0),
                    rgba(233, 246, 255, 0.8) 40%,
                    rgba(233, 246, 255, 0.8) 60%,
                    rgba(233, 246, 255, 0)
                    );
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 84% auto;
                }
                &:first-child::before {
                    display: none;
                }
            }
        }
        span {
            font-size: 18px;
            font-weight: 700;

            position: relative;
            z-index: 1;

            color: #010F54;
            &::before {
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 50%;

                width: 70px;
                height: 70px;

                content: "";
                transform: translateX(-50%) translateY(-50%);

                border-radius: 100%;
                background: linear-gradient(-90deg, #FBFDFF, #E9F6FF);
            }
        }
        .price {
            margin-top: 44px;
            margin-bottom: 42px;
            p {
                font-size: 40px;
                font-weight: 500;
                line-height: 47px;

                color: #010F54;
                sup {
                    top: -7px;
                    left: 8px;
                }
                sub {
                    font-size: 16px;

                    left: -8px;
                }
            }
        }
    }
}
