@import "../../../assets/scss/variables";

.sidebarWrapper {
    @media #{$md-layout,
    $sm-layout} {
        margin-top: 50px;
    }
}

.sidebar {
    margin-bottom: 50px;
    padding: 30px;

    background-color: #F7F8F9;
    &.sidebarTwo {
        padding: 0;

        background-color: transparent;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.sidebarTitle {
    margin-top: -4px;
    margin-bottom: 30px;
}

.sidebarList {
    padding-left: 0;

    list-style: none;
    & li {
        margin-bottom: 10px;
        padding-bottom: 10px;

        border-bottom: 1px solid #E5E6E7;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;

            border-bottom: 0 solid transparent;
        }
        & a {
            font-size: 14px;
            font-weight: 600;

            display: flex;
            align-items: center;

            color: #333;
            & img {
                width: 25px;
                margin-right: 15px;
            }
            & svg {
                font-size: 24px;

                margin-right: 15px;
            }
            &:hover {
                color: $theme-color;
            }
        }
    }
}
